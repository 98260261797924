export default {
  SET_USER(state, user) {
    state.user = user;
  },
  SET_LOADING(state, { type, loading }) {
    const loadState = { ...state.loading };
    loadState[type] = loading;
    state.loading = { ...loadState };
  },
};

import Vue from "vue";
import axios from "axios";
import get from "lodash/get";

import { API_END_POINT } from "@/config/index";

const service = axios.create({
  baseURL: API_END_POINT
});

service.interceptors.request.use(
  config => {
    const token = localStorage.getItem("token");
    if (token && config.headers) {
      config.headers.Authorization = token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    const token = response.headers["x-amzn-remapped-authorization"];
    if (token) {
      localStorage.setItem("token", token);
    }
    return response;
  },
  error => {
    let errorMsg = get(
      error,
      "response.data.message",
      "Something Went Wrong...!"
    );

    const errorsArr = [
      "Token not provided",
      "Token has expired",
      "Your token has expired. Please, login again.",
      "Token Signature could not be verified.",
      "User not found"
    ];

    if (errorsArr.includes(errorMsg)) {
      localStorage.removeItem("token");
    }

    if (errorMsg === "Validation Error") {
      const errorObj = { ...error.response.data };
      delete errorObj.message;

      for (const key in errorObj) {
        const element = errorObj[key];
        const elKeys = Object.keys(element);

        if (elKeys.length) {
          errorMsg = element[elKeys[0]][0];
        }
      }
    }

    Vue.notify({
      group: "notification",
      title: "Some Error",
      text: errorMsg ? errorMsg : 'Something went wrong',
      type: "error",
      duration: 5000
    });
    return Promise.reject(error);
  }
);

export default service;

import { createPetObject } from "./state";

export default {
  SET_LOADING(state, { loading, type }) {
    state.loading[type] = loading;
  },
  SET_PET_LIST(state, { type, list }) {
    state[type] = list;
  },
  PUSH_NEW_PET(state, pet) {
    state.activeList.push(pet);
  },
  UPDATE_PET(state, pet) {
    state.pet = pet;
    const keys = ["activeList", "adoptionList", "coAdoptionList"];

    keys.forEach((key) => {
      const index = state[key].findIndex((p) => p.id === pet.id);
      if (index !== -1) {
        const copiedPets = [...state[key]];
        copiedPets[index] = pet;
        state[key] = copiedPets;
      }
    });
  },
  SET_PET(state, pet) {
    state.pet = pet;
  },
  SET_PET_REQUESTS(state, requests) {
    state.requests = requests;
  },
  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },
  SET_REQUEST_READ(state, requests){
    state.requests = requests;
  },
  SET_SUB_CATEGORIES(state, payload) {
    state.subCategories = payload;
  },
  RESET_CREATE_PET_OBJ(state) {
    state.createPet = { ...createPetObject() };
  },
  SET_CREATE_PET(state, { key, value }) {
    const createPet = { ...state.createPet };
    createPet[key] = value;
    state.createPet = { ...createPet };
  },
  ADD_PET_TO_EDIT(state, pet) {
    const copyPet = { ...pet };
    if (typeof copyPet.meta_data === "string") {
      copyPet.meta_data = JSON.parse(pet.meta_data);
    }
    copyPet.media = copyPet.media.map((media) => {
      return {
        id: Date.now() + Math.random(),
        src: media,
      };
    });

    if (pet.race) {
      copyPet.race_id = pet.race.id;
    }

    state.createPet = copyPet;
  },
};

import { calculateAge } from "@/helpers/functions";
import actions from "./actions";
import mutations from "./mutations";
import state from "./state";

export default {
  namespaced: true,
  state,
  getters: {
    GET_PET_PAYLOAD(state) {
      const pet = state.createPet;
      pet.age = calculateAge(pet.date_of_birth);
      const notAddKeys = ["category", "race", "likes"];
      let petObj = {};

      for (const key in pet) {
        if (key === "meta_data") {
          const meta_data = pet.meta_data;
          petObj.meta_data = JSON.stringify(meta_data);
        } else if (key === "media") {
          petObj.media = pet.media.map((m) => {
            if (!m.file) return m.src;
            else return m.file;
          });
        } else if (notAddKeys.includes(key)) {
          // do nothing
          continue;
        } else {
          petObj[key] = pet[key];
        }
      }

      return petObj;
    },
  },
  mutations,
  actions,
};

import axios from '@/plugins/axios';
import webAxios from "@/plugins/web-api";

export default {
    namespaced: true,
    state: {},
    actions : {
        async getConnectionData(){
            return await axios.get('/seller/get-connection-data');
        },
        async redirection(context, userId){
            return await webAxios.get(`/redirect/${userId}`);
        }
    },
    mutations : {},
}
<template>
  <v-text-field
    @input="emitValue"
    :value="value"
    :label="label"
    :type="inputType"
    :rules="rules"
    :name="name"
    :append-icon="getAppendIcon"
    :hide-details="hideDetails"
    :placeholder="placeholder"
    @click:append="show = !show"
    :disabled="disabled"
  ></v-text-field>
  <!-- :class="!hideDetails ? 'mb-2' : ''" -->
</template>

<script>
export default {
  name: "input-group",
  props: {
    value: [String, Number],
    type: { type: String, default: "text" },
    label: { type: String, default: "" },
    rules: { type: Array, default: () => [] },
    appendIcon: { type: String, default: "" },
    name: { type: String, default: String(Math.random()) },
    hideDetails: { type: Boolean, default: false },
    disabled:{type:Boolean, default:false},
    placeholder: { type: String, default: "" },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    getAppendIcon() {
      if (this.type === "password") {
        if (this.show) return "mdi-eye";
        else return "mdi-eye-off";
      } else {
        return this.appendIcon;
      }
    },
    inputType() {
      if (this.type === "password") {
        if (this.show) {
          return "text";
        } else {
          return "password";
        }
      } else {
        return this.type;
      }
    },
  },
  methods: {
    emitValue(e) {
      this.$emit("input", e);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field {
  padding-top: 0 !important;
  margin-top: 4px;
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Store from "@/store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    component: () => import("@/layout/default-layout.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/",
        name: "pets",
        component: () => import("../views/pet/Index.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/connection",
        name: "connection",
        component: () => import("../views/social/social.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/pet/:id",
        name: "pets-details",
        component: () => import("../views/pet/pet-detail.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("../views/profile/Profile.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "",
    component: () => import("@/layout/auth-layout.vue"),
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: "/login",
        name: "login",
        component: () =>
          import(/* webpackChunkName: "login" */ "@/views/auth/login.vue"),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/register",
        name: "register",
        component: () =>
          import(
            /* webpackChunkName: "register" */ "@/views/auth/register.vue"
          ),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/verify-otp",
        name: "verify-otp",
        component: () =>
          import(/* webpackChunkName: "otp" */ "@/views/auth/verify-otp.vue"),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/forgot-password",
        name: "forgot-password",
        component: () =>
          import(
            /* webpackChunkName: "forgot-password" */ "@/views/auth/forgot-password.vue"
          ),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/reset-password",
        name: "reset-password",
        component: () =>
          import(
            /* webpackChunkName: "reset-password" */ "@/views/auth/reset-password.vue"
          ),
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  const token = localStorage.getItem("token");
  let storeUser = Store.state.user.user;
  let isAuthenticated = false;

  if (token) {
    if (!storeUser) {
      await Store.dispatch("user/FETCH_USER");
    }

    storeUser = Store.state.user.user;
    if (storeUser) {
      isAuthenticated = true;
    }
  }

  if (requiresAuth && isAuthenticated) {
    next();
  } else if (requiresAuth && !isAuthenticated) {
    next("/login");
  } else if (!requiresAuth && isAuthenticated) {
    next("/");
  } else {
    next();
  }
});

export default router;

import actions from "./actions";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: {
    user: null,
    loading: {
      logout: false,
      avatar: false,
      user: false,
    },
  },
  getters: {},
  mutations,
  actions,
};

<template>
  <v-btn
    @click="$emit('click')"
    :color="color"
    :outlined="outlined"
    :block="block"
    :loading="loading"
    :disabled="disabled || loading"
    :large="large"
    class="elevation-0"
    :dark="dark"
    :depressed="depressed"
    :type="type"
    ><slot></slot
  ></v-btn>
</template>

<script>
export default {
  name: "app-button",
  props: {
    color: { type: String, default: "primary" },
    type: { type: String, default: "button" },
    outlined: { type: Boolean, default: false },
    block: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    large: { type: Boolean, default: false },
    dark: { type: Boolean, default: false },
    depressed: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss">
.v-application {
  .v-btn {
    letter-spacing: 0;
    border-radius: 8px;
    text-transform: capitalize;
  }
}
</style>

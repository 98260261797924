import Vue from "vue";
import Notifications from "vue-notification";
import velocity from "velocity-animate";
import vueAxios from "vue-axios";
import * as VueGoogleMaps from "vue2-google-maps";

import axios from "./axios";
import { GOOGLE_MAP_KEY } from "@/config";
import Button from "@/components/button/Button";
import InputGroup from "@/components/form/InputGroup";
import Loader from "@/components/loader/loader";

Vue.component("Button", Button);
Vue.component("InputGroup", InputGroup);
Vue.component("Loader", Loader);

Vue.use(vueAxios, axios);
Vue.use(Notifications, { velocity });
Vue.use(VueGoogleMaps, {
  load: {
    key: GOOGLE_MAP_KEY,
    libraries: "places"
  }
});

// filters
Vue.filter("trim", (val, limit = 10, add = "") => {
  return val.substring(0, limit) + add;
});

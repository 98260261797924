import Vue from "vue";
import Vuex from "vuex";
import userModule from "./modules/user/index";
import petModule from "./modules/pet";
import connectionModule from './modules/connection/index';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user: userModule,
    pet: petModule,
    connection: connectionModule
  }
});

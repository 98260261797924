import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueSocials from 'vue-socials';
import VueSocialSharing from 'vue-social-sharing'

import "@/plugins/index";
import "@/scss/main.scss";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  VueSocials,
  VueSocialSharing,
  render: (h) => h(App),
}).$mount("#app");

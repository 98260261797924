export const GENDER_LIST = [
  {
    text: "Male",
    value: "male",
  },
  {
    text: "Female",
    value: "female",
  },
  {
    text: "Other",
    value: "other",
  },
];

export const SELLER_STATUS = {
  APPROVED: "approved",
  PENDING: "pending",
  REJECTED: "rejected",
};

export const ADOPTION_STATUS = {
  APPROVED: "approved",
  PENDING: "pending",
  REJECTED: "rejected",
  CO_APPROVED: "co_approved",
};

export const ADOPTION_REQUEST_STATUS = {
  REVIEW: "review",
  APPROVED: "approved",
  REJECTED: "rejected",
};

export const GARDEN_SIZES = [
  {
    text: "No Garden",
    value: "no",
  },
  {
    text: "Less then 200 meter square",
    value: "small",
  },
  {
    text: "More then 200 meter square",
    value: "big",
  },
];

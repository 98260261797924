import axios from '@/plugins/axios';
import router from '@/router';
import { ADOPTION_STATUS, ADOPTION_REQUEST_STATUS } from '@/constants';
import UploadService from '@/plugins/upload-service';

const uploadMedia = async (files) => {
  const fd = new FormData();
  for (let i = 0; i < files.length; i++) {
    fd.append(`media[${i}]`, files[i]);
  }
  return UploadService.post('/add-media', fd);
};

export default {
  async FETCH_PET_LIST({ commit }) {
    try {
      commit('SET_LOADING', { loading: true, type: 'pets' });
      const { data } = await axios.get('/seller/show-by-seller-animal');
      if (data.data.animals) {
        const pets = data.data.animals;
        const activeList = pets.filter(
          (pet) => pet.adoption_status === ADOPTION_STATUS.PENDING
        );
        const adoptionList = pets.filter(
          (pet) => pet.adoption_status === ADOPTION_STATUS.APPROVED
        );
        const coAdoptionList = pets.filter(
          (pet) => pet.adoption_status === ADOPTION_STATUS.CO_APPROVED
        );

        commit('SET_PET_LIST', { type: 'activeList', list: activeList });
        commit('SET_PET_LIST', { type: 'adoptionList', list: adoptionList });
        commit('SET_PET_LIST', {
          type: 'coAdoptionList',
          list: coAdoptionList,
        });
      }
      commit('SET_LOADING', { loading: false, type: 'pets' });
    } catch (error) {
      commit('SET_LOADING', { loading: false, type: 'pets' });
    }
  },

  async FETCH_SINGLE_PET({ commit }, id) {
    try {
      commit('SET_LOADING', { loading: true, type: 'petDetail' });
      const { data } = await axios.get(
        `/seller/show-seller-animal-detail/${id}`
      );

      const { animal, adoption_requests } = data.data;
      if (typeof animal.meta_data === 'string') {
        animal.meta_data = JSON.parse(animal.meta_data);
      }
      commit('ADD_PET_TO_EDIT', animal);
      commit('SET_PET', animal);
      commit('SET_PET_REQUESTS', adoption_requests);
      commit('SET_LOADING', { loading: false, type: 'petDetail' });
    } catch (error) {
      console.log(error);
      commit('SET_LOADING', { loading: false, type: 'petDetail' });
    }
  },

  async FETCH_PET_CATEGORIES({ commit, state }) {
    try {
      commit('SET_LOADING', { loading: true, type: 'category' });
      const categories = state.categories;
      if (categories.length) {
        commit('SET_CATEGORIES', categories);
      } else {
        const { data } = await axios.get('/web/show-all-categories');
        commit('SET_CATEGORIES', data.data);
      }
      commit('SET_LOADING', { loading: false, type: 'category' });
    } catch (e) {
      commit('SET_LOADING', { loading: false, type: 'category' });
    }
  },

  async FETCH_SUB_CATEGORIES({ commit }, id) {
    try {
      commit('SET_LOADING', { loading: true, type: 'subCategory' });
      const { data } = await axios.get(`/web/show-all-sub-categories/${id}`);
      commit('SET_SUB_CATEGORIES', data.data);
      commit('SET_LOADING', { loading: false, type: 'subCategory' });
    } catch (e) {
      commit('SET_LOADING', { loading: false, type: 'subCategory' });
    }
  },

  async CREATE_PET({ commit, getters, dispatch }) {
    try {
      const payload = getters.GET_PET_PAYLOAD;
      commit('SET_LOADING', { loading: true, type: 'createPet' });
      const mediaResp = await uploadMedia(payload.media);
      const media = mediaResp.data.data;
      payload.media = media;
      const resp = await axios.post('/seller/add-animal', payload);
      const { animal } = resp.data.data;
      animal.adoption = 0;
      animal.co_adoption = 0;
      animal.likes = 0;
      commit('SET_LOADING', { loading: false, type: 'createPet' });
      dispatch('FETCH_PET_LIST');
      return true;
    } catch (error) {
      console.log(error);
      commit('SET_LOADING', { loading: false, type: 'createPet' });
      return false;
    }
  },

  async UPDATE_PET({ commit, state, getters }) {
    try {
      const payload = getters.GET_PET_PAYLOAD;
      const id = state.createPet.id;
      commit('SET_LOADING', { loading: true, type: 'createPet' });
      const files = payload.media.filter((m) => typeof m === 'object');
      const src = payload.media.filter((m) => typeof m === 'string');
      let media = [...src];
      if (files.length) {
        const mediaResp = await uploadMedia(files);
        media = [...media, ...mediaResp.data.data];
      }
      payload.media = media;
      const resp = await axios.post(`/seller/update-animal/${id}`, payload);
      const { animal } = resp.data.data;
      animal.likes = animal.lines || 0;

      if (typeof animal.meta_data === 'string') {
        animal.meta_data = JSON.parse(animal.meta_data);
      }

      commit('UPDATE_PET', animal);
      commit('SET_LOADING', { loading: false, type: 'createPet' });
      return true;
    } catch (error) {
      console.log(error);
      commit('SET_LOADING', { loading: false, type: 'createPet' });
      return false;
    }
  },

  async DELETE_PET({ commit }, id) {
    try {
      commit('SET_LOADING', { loading: true, type: 'petDelete' });
      await axios.delete(`/seller/delete-animal/${id}`);
      commit('SET_PET', null);
      commit('SET_PET_REQUESTS', []);
      commit('SET_LOADING', { loading: false });
      router.replace('/');
    } catch (e) {
      commit('SET_LOADING', { loading: false, type: 'petDelete' });
    }
  },

  async ACCEPT_REQUEST({ commit, state }, id) {
    try {
      commit('SET_LOADING', { loading: true, type: 'acceptRequest' });
      await axios.get(`/seller/approve-adoption/${id}`);
      const requests = [...state.requests];
      const index = requests.findIndex((req) => req.id === id);
      if (index !== -1) {
        const req = { ...requests[index] };
        req.status = ADOPTION_REQUEST_STATUS.APPROVED;
        requests[index] = req;
        commit('SET_PET_REQUESTS', [...requests]);
      }
      commit('SET_LOADING', { loading: false, type: 'acceptRequest' });
    } catch (error) {
      console.log(error);
      commit('SET_LOADING', { loading: false, type: 'acceptRequest' });
    }
  },

  async REJECT_REQUEST({ commit, state }, id) {
    try {
      commit('SET_LOADING', { loading: true, type: 'rejectRequest' });
      await axios.get(`/seller/reject-adoption/${id}`);
      const requests = [...state.requests];
      const index = requests.findIndex((req) => req.id === id);
      if (index !== -1) {
        const req = { ...requests[index] };
        req.status = ADOPTION_REQUEST_STATUS.REJECTED;
        requests[index] = req;
        commit('SET_PET_REQUESTS', [...requests]);
      }
      commit('SET_LOADING', { loading: false, type: 'rejectRequest' });
    } catch (error) {
      console.log(error);
      commit('SET_LOADING', { loading: false, type: 'rejectRequest' });
    }
  },
  async MARK_REQUEST_READ({ commit, state }, id) {
    try {
      
      await axios.get(`seller/read-adoption-request/${id}`);
      const requests = [...state.requests];
      const index = requests.findIndex((req) => req.id === id);
      if (index !== -1) {
         const req = { ...requests[index] };
         req.read = 1;
        requests[index] = req;
         commit('SET_REQUEST_READ', [...requests]);
      }
    } catch (error) {
      console.log(error);
    }
  },
};

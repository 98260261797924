import moment from "moment";
export const calculateAge = dob => {
  const today = moment();
  dob = moment(dob);

  const years = today.diff(dob, "years");
  const months = today.diff(dob, "months");
  const days = today.diff(dob, "days");

  let yearStr = "Yr";
  let monthStr = "Mo";
  let dayStr = "D";

  if (years > 1) yearStr = "Yr's";
  if (months > 1) monthStr = "Mo's";
  if (days > 1) dayStr = "D's";

  if (years >= 1) return `${years} ${yearStr}`;
  if (months >= 1) return `${months} ${monthStr}`;
  if (days >= 1) return `${days} ${dayStr}`;

  return "Nothing";
};

import axios from '@/plugins/axios';
import router from '@/router';
import UploadService from '@/plugins/upload-service';

export default {
  async CREATE_USER({ commit }, payload) {
    try {
      commit('SET_LOADING', { loading: true, type: 'user' });
      const resp = await axios.post('/auth/register', payload);
      const user = resp.data.data;
      commit('SET_USER', user);
      router.push('/');
      commit('SET_LOADING', { loading: false, type: 'user' });
    } catch (error) {
      commit('SET_LOADING', { loading: false, type: 'user' });
      console.log(error);
    }
  },

  async FETCH_USER({ commit }) {
    try {
      commit('SET_LOADING', { loading: true, type: 'user' });
      const resp = await axios.get('/auth/show-user');
      const { user } = resp.data.data;
      commit('SET_USER', user);
      commit('SET_LOADING', { loading: false, type: 'user' });
    } catch (error) {
      commit('SET_LOADING', { loading: false, type: 'user' });
      console.log(error);
    }
  },

  async LOGIN({ commit }, payload) {
    try {
      commit('SET_LOADING', { loading: true, type: 'user' });
      const resp = await axios.post('/auth/login', payload);
      const user = resp.data.data;
      commit('SET_USER', user);
      router.push('/');
      commit('SET_LOADING', { loading: false, type: 'user' });
    } catch (error) {
      commit('SET_LOADING', { loading: false, type: 'user' });
      console.log(error);
    }
  },

  async LOGOUT({ commit }) {
    try {
      commit('SET_LOADING', { loading: true, type: 'logout' });
      await axios.post('/auth/logout');
      commit('SET_LOADING', { loading: false, type: 'logout' });
      localStorage.removeItem('token');
      commit('SET_USER', null);
      // router.push("/login");
      window.location.href = '/login';
    } catch (error) {
      commit('SET_LOADING', { loading: false, type: 'logout' });
      console.log(error);
    }
  },

  async UPDATE_PROFILE({ commit }, payload) {
    try {
      commit('SET_LOADING', { loading: true, type: 'user' });
      const resp = await axios.post('/auth/update-profile', payload);
      const { user } = resp.data.data;
      commit('SET_USER', user);
      commit('SET_LOADING', { loading: false, type: 'user' });
    } catch (error) {
      commit('SET_LOADING', { loading: false, type: 'user' });
    }
  },

  async UPLOAD_AVATAR({ commit }, avatar) {
    try {
      commit('SET_LOADING', { loading: true, type: 'avatar' });
      const mediaResp = await UploadService.post('/add-media', avatar);
      const media = mediaResp.data.data[0];
      const resp = await axios.post('/auth/upload-avatar', { avatar: media });
      const { user } = resp.data.data;
      commit('SET_USER', user);
      commit('SET_LOADING', { loading: false, type: 'avatar' });
      return true;
    } catch (error) {
      commit('SET_LOADING', { loading: false, type: 'avatar' });
      console.log(error);
      return false;
    }
  },

  async FORGOT_PASSWORD({ commit }, payload) {
    try {
      commit('SET_LOADING', { loading: true, type: 'user' });
      await axios.post('/auth/request-otp', payload);
      router.push(`/verify-otp?email=${payload.email}`);
      commit('SET_LOADING', { loading: false, type: 'user' });
    } catch (error) {
      commit('SET_LOADING', { loading: false, type: 'user' });
      console.log(error);
      return false;
    }
  },

  async VERIFY_OTP({ commit }, payload) {
    try {
      commit('SET_LOADING', { loading: true, type: 'user' });
      await axios.post('/auth/verify-otp', payload);
      router.push(`/reset-password?email=${payload.email}`);
      commit('SET_LOADING', { loading: false, type: 'user' });
      return true;
    } catch (error) {
      commit('SET_LOADING', { loading: false, type: 'user' });
      console.log(error);
      return false;
    }
  },

  async RESET_PASSWORD({ commit }, payload) {
    try {
      commit('SET_LOADING', { loading: true, type: 'user' });
      await axios.post('/auth/update-new-password', payload);
      router.replace(`/login`);
      commit('SET_LOADING', { loading: false, type: 'user' });
      return true;
    } catch (error) {
      commit('SET_LOADING', { loading: false, type: 'user' });
      console.log(error);
      return false;
    }
  },
};

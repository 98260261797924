export const createPetObject = () => {
  return {
    category_id: "",
    race_id: "",
    name: "",
    vaccine: false,
    age: 0,
    date_of_birth: "",
    media: [],
    description: "",
    disclaimer: "",
    adoption: 0,
    co_adoption: 0,
    // location related
    city: "",
    country: "",
    address: "",
    postal_code: 0,
    longitude: 0,
    latitude: 0,
    // metaData
    meta_data: {
      garden: "",
      garden_length: "",
      garden_width: "",
      garden_size: "",
      active_hours: "",
      minimal_adopt: "",
      pet_interaction: [],
    },
  };
};

export default {
  activeList: [],
  adoptionList: [],
  coAdoptionList: [],
  pet: null,
  requests: [],
  categories: [],
  subCategories: [],
  loading: {
    pets: false,
    petDetail: false,
    category: false,
    subCategory: false,
    createPet: false,
    petDelete: false,
    rejectRequest: false,
    acceptRequest: false,
  },
  createPet: { ...createPetObject() },
};

import axios from "axios";
import get from "lodash/get";
import Vue from "vue";
import { WEB_API_URL } from "@/config";

const token = localStorage.getItem('token');

const WebApiURL = axios.create({
  baseURL: WEB_API_URL,
  headers: {
    "content-type": "multipart/form-data",
    "Authorization" : token
  }
});

WebApiURL.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

WebApiURL.interceptors.response.use(
  resp => resp,
  error => {
    let errorMsg = get(
      error,
      "response.data.message",
      "Something Went Wrong...!"
    );

    const errorsArr = [
      "Token not provided",
      "Token has expired",
      "Your token has expired. Please, login again.",
      "Token Signature could not be verified.",
      "User not found"
    ];

    if (errorsArr.includes(errorMsg)) {
      localStorage.removeItem("token");
    }

    Vue.notify({
      group: "notification",
      title: "Some Error",
      text: errorMsg ? errorMsg : 'Something went wrong',
      type: "error",
      duration: 5000
    });
    return Promise.reject(error);
  }
);

export default WebApiURL;

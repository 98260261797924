import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  default: "light",
  theme: {
    themes: {
      light: {
        primary: "#0DBFF2",
        secondary: "#5DEFCC",
        accent: "#8143F9",
        error: "#FF5F5F",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
        golden: "#F6C15A",
        dark: "#333",
        "dark-blue": "#03354D",
        "light-pink": "#F39D88",
        "grey-light": "#F7F7F7",
        "light-bg": "#f4f4f4",
        "bg-yellow": "#F5F2E9",
        grey: "#7F7D94",
        "light-grey": "#bfbec9",
      },
    },
  },
});

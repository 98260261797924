<template>
  <v-app>
    <router-view />
    <notifications group="notification" animation-type="velocity" />
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  })
};
</script>
